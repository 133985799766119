import Countly from "countly-sdk-web";

const getEnvironment = () => {
    return window.location.origin === 'http://localhost:3000'
        ? 'local'
        : window.location.origin.split('.')[0].replace('https://', '');
}

export const getAppKey = () => {
    switch (getEnvironment()) {
        case 'karriere':
                return '1cec11c50abb4d0ab84462923705805554c7f388';  // prod
        case 'develop':
                return '6ed341a8dd5630356f60792484af03a4a141bfc4'; // develop
        default:
            return '083273286f6be7f331012c29e25695e763110ba8'; // local
    }
}

// Initialize countly

window.Countly = Countly;
Countly.init({
    app_key: getAppKey(),
    url: 'https://countly.it-werklerei.de/',
});

Countly.q.push(
    ['track_sessions'],
    ['track_pageview'],
    ['track_clicks'],
    ['track_scrolls'],
    ['track_errors'],
    ['track_links'],
    ['track_forms'],
    ['collect_from_forms'],
);

// ###############

export const overviewOpened = () => {
    Countly.add_event({
        key: 'Entry page opened',
        segmentation: {
            "client": getEnvironment(),
        },
    });
}

export const jobOpened = (job) => {
    Countly.add_event({
        key: 'Job page opened',
        segmentation: {
            "client": getEnvironment(),
            "job": job,
        },
    });
}

export const benefitsOpened = (job, funnelButtonText, benefitPageIndex) => {
    Countly.add_event({
        key: 'Benefit page opened',
        segmentation: {
            "client": getEnvironment(),
            "job": job,
            "funnelButton": funnelButtonText,
            "benefitPage": benefitPageIndex,
        },
    });
}

export const contactOpened = (job) => {
    Countly.add_event({
        key: 'Contact page opened',
        segmentation: {
            "client": getEnvironment(),
            "job": job,
        },
    });
}

export const formFocusLost = (job, formId, formValue) => {
    Countly.add_event({
        key: 'Contact form field changed',
        segmentation: {
            "client": getEnvironment(),
            "job": job,
            "formId": formId,
            "formValue": formValue,
        },
    });
}

export const applicationSent = (job, formFields) => {
    Countly.add_event({
        key: 'User sent an application',
        segmentation: {
            "client": getEnvironment(),
            "job": job,
            "formFields": formFields,
        },
    });
}

export const homepageOpened = () => {
    Countly.add_event({
        key: 'User went back to homepage',
        segmentation: {
            "client": getEnvironment()
        },
    });
}
