import React, {useEffect} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Button, Layout} from "antd";
import {Col as BootstrapCol, Container, Row as BootstrapRow} from "react-bootstrap";
import logo_text from "../../assets/logo_einzeiler_200.png";
import * as countly from "../../services/countlyService";
import {CareerJob} from "../../constants/jobs";

const {Header, Content, Footer} = Layout;

export interface IJobPageProps {
    job: CareerJob;
}

export interface ICareerPageProps {
    jobs: CareerJob[];
}

export const CareerPageLayout = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Layout>
            <Header className={'header'}>
                <img src={logo_text} alt='Unser Logo' className={'responsive-image'} style={{marginTop: 20}}/>
            </Header>
            <Content style={{background: 'white', minHeight: '86vh'}}>
                <Outlet/>
            </Content>
            <Footer className={'footer'}>
                <Link to={'https://it-werklerei.de/impressum'} className={'a-footer'}>Impressum</Link>
                <span>&nbsp;</span>
                <span>·</span>
                <span>&nbsp;</span>
                <Link to={'https://it-werklerei.de/privacy'} className={'a-footer'}>Datenschutz</Link>
            </Footer>
        </Layout>
    );
}

export const renderHeadBanner = () => {
    return (
        <Container className={'box'}>
            <div className={'box-container'}>
                <div className={'box-text'}>
                    Sicher Dir deinen Traumjob - <strong>ohne</strong> Anschreiben und Lebenslauf! 🚀
                </div>
            </div>
        </Container>
    );
}

export const renderAboutUs = () => {
    return (
        <div className={'banner-inline'}>
            <h2 style={{fontSize: 26}}>
                Wir stellen uns vor 🔥
            </h2>
            <h4 className={'text-col'} style={{marginBottom: 27, lineHeight: '40px'}}>
                Die it werklerei ist ein agiles IT-Unternehmen mit Sitz im Münchner Umfeld.
                <br/>
                Unser Ursprung liegt in der Überzeugung, dass Menschen an einem inspirierenden Arbeitsplatz
                produktiver sind.
                <br/>
                Bei uns sind Talent, Fleiß und vor allem Leidenschaft entscheidende Treiber für exzellente
                Ergebnisse – für unsere Mitarbeiter und unsere Projekte.
            </h4>
        </div>
    );
}

const EntryPage = ({jobs}: ICareerPageProps) => {
    const navigate = useNavigate();

    const goToJob = (path: string) => {
        navigate(path);
    }

    countly.overviewOpened()

    return (
        <div>
            {renderHeadBanner()}
            <div className={'text-inner'}>
                <h5>📍 München & Remote</h5>
                <p>🕐 Vollzeit</p>
            </div>
            <div className={'text-box'}>
                <h3 style={{fontSize: 24}}>
                    Hast du Lust auf einen<span>&nbsp;</span>
                    <b>sicheren Job</b>,
                    <span>&nbsp;</span>mit vielen weiteren Vorteilen wie z.B.
                    <span>&nbsp;</span><b>mobiles Arbeiten</b><span>&nbsp;</span>
                    und <b>flexiblen Arbeitszeiten</b>?
                </h3>
            </div>
            <div className={'banner'} style={{padding: '0 50px'}}>
                <BootstrapRow className={'justify-content-center'}>
                    <h2 style={{marginTop: 30}}>Welcher Job passt zu dir?</h2>
                    {jobs.map((job, index) => {
                        const path = job.jobTitle.replace(' ', '-').toLowerCase();
                        return (
                            <BootstrapCol xl={{span: 4}} key={`job-${index}`}>
                                <div style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
                                    <Button
                                        type='primary'
                                        className={'extra-large-button'}
                                        onClick={() => goToJob(path)}
                                    >
                                        {job.jobTitle}
                                    </Button>
                                </div>
                            </BootstrapCol>
                        );
                    })}
                </BootstrapRow>
            </div>
            {renderAboutUs()}
        </div>
    );
}

export default EntryPage;