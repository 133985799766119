import React from "react";
import {Button} from "antd";
import {Card, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import chuck_norris from "../assets/chuck_norris.png";

export const NotFoundPage = () => {
    const navigate = useNavigate();

    const goToStart = () => {
        navigate("/");
    }

    return (
        <div style={{margin: '60px 0'}}>
            <h1>404 – Seite nicht gefunden</h1>
            <Row style={{margin: '10%'}}>
                <Col lg={4}>
                    <img src={chuck_norris} alt='Unser Logo' className={'responsive-image'} style={{marginTop: 20}}/>
                </Col>
                <Col lg={8}>
                    <Card style={{background: 'rgb(250, 250, 250)', border: 'none', textAlign: 'left', marginTop: 40}}>
                        <h3 style={{margin: 40}}>
                            Sorry, Chuck Norris hat die Seite mitgenommen und gibt sie nicht wieder her!
                        </h3>
                        <div style={{padding: '0 30px'}}>
                            <Button
                                type={'link'}
                                className={'submit-button'}
                                style={{marginTop: 30}}
                                onClick={goToStart}
                            >
                                Zurück zur Startseite 🔙
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}