import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "antd";
import {Col as BootstrapCol, Container, Row as BootstrapRow} from "react-bootstrap";
import * as countly from "../../services/countlyService";
import {Benefit, benefitsList, benefitsText} from "../../constants/benefits";

export const JobBenefitsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const pathIndex = parseInt(location.pathname.at(location.pathname.length - 1) || '1');
    const benefitIndex = pathIndex - 2;

    const goToNextPage = (buttonText: string) => {
        const path = location.pathname.slice(0, location.pathname.length - 2);
        if(benefitIndex === 0) {
            countly.benefitsOpened(path, buttonText,pathIndex);
        } else {
            countly.contactOpened(path);
        }
        navigate(`${path}-${pathIndex + 1}`);
    }

    const getBenefit = (benefit: Benefit, index: number) => {
        return (
            <BootstrapCol span={12} xxs={12} md={6} style={{marginBottom: 25}} key={`benefit-${index}`}>
                <p className={'text-icon'}>{benefit.icon}</p>
                <h5>
                    <strong className={'text-col-title'}>{benefit.title}:</strong>
                </h5>
                <h4>{benefit.description}.</h4>
            </BootstrapCol>
        );
    }

    return (
        <Container>
            <BootstrapRow className="justify-content-md-center">
                <BootstrapCol style={{padding: '70px'}}>
                    <h1>
                        <strong>{benefitsText[benefitIndex].title}</strong>
                    </h1>
                </BootstrapCol>
                <BootstrapRow>
                    {benefitsList[benefitIndex].map((benefit, index) => {
                        return getBenefit(benefit, index);
                    })}
                </BootstrapRow>
                <BootstrapCol>
                    <div style={{paddingTop: '4rem', paddingBottom: '4rem'}}>
                        <Button
                            type='primary'
                            className={'large-button'}
                            onClick={() => goToNextPage(benefitsText[benefitIndex].buttonText)}
                        >
                            {benefitsText[benefitIndex].buttonText}
                        </Button>
                    </div>
                </BootstrapCol>
            </BootstrapRow>
        </Container>
    );
}