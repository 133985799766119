import axios from "axios";

export const getBaseUrl = () => window.location.origin === 'http://localhost:3000'
    ? 'http://localhost:8085'
    : window.location.origin;

export const getUrl = (path: string) => getBaseUrl() + path;

const axiosClient = axios.create();
axiosClient.defaults.baseURL = getBaseUrl();
axiosClient.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache' // Safari
}
axiosClient.defaults.timeout = 5000;

export const getRequest = (url: string) => {
    return axiosClient.get(`${url}`).then(response => response);
}

export const postRequest = (url: string, body: any) => {
    return axiosClient.post(`${url}`, body);
}