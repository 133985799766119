export type Benefit = {
    icon: string;
    title: string;
    description: string;
};

export const benefitsText = [
    {
        title: 'Deine Vorteile bei der it-werklerei 💪',
        buttonText: 'Was bietet ihr mir noch? 🙌',
    },
    {
        title: 'Weitere Vorteile bei der it-werklerei 💪',
        buttonText: 'Auf zum letzten Schritt! 🏁',
    },
];

export const benefitsList: Benefit[][] = [
    [
        {
            icon: '🏡',
            title: 'Remote Arbeiten',
            description: 'Uns wäre neben dem remote arbeiten wichtig, dass wir uns 2 mal im Monat in unserem Büro zusammentreffen',
        },
        {
            icon: '💰',
            title: 'Faires Gehalt',
            description: 'Wir bieten dir ein leistungsorientiertes Gehaltssystem an',
        },
        {
            icon: '🎓',
            title: 'Individuelle Weiterbildung',
            description: 'Wir setzen auf die individuelle Förderung unserer Mitarbeiter anhand ihrer Qualifikation und Passion. ' +
                'Wir bieten unseren Java Entwicklern z.B. Lehrgänge oder Onlinekurse an',
        },
        {
            icon: '📝',
            title: 'Unbefristete Arbeitsverträge',
            description: 'Damit du dich optimal auf die Arbeit und unsere Kunden konzentrieren kannst, bieten wir dir einen unbefristeten Arbeitsvertrag an'
        },
    ],
    [
        {
            icon: '🏖️',
            title: 'Flexible Arbeitszeiten',
            description: 'Neben 31 Tagen Urlaub im Jahr bieten wir ein flexibles Arbeitszeitmodell an'
        },
        {
            icon: '🚴‍♂️',
            title: 'Job-Bike',
            description: 'Auf Wunsch erhältst du von uns ein Job-Bike'
        },
        {
            icon: '🧠',
            title: 'Freiraum, um viel zu bewegen',
            description: 'Bei uns erhältst genug Freiraum sowohl beim Arbeiten als auch für eigene Projekte oder Ideen'
        },
        {
            icon: '💚',
            title: 'Das wichtigste',
            description: 'Sehr flache Hierarchien, Teamevents und offene Türen'
        },
    ],
];