import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ConfigProvider} from "antd";
import './App.css';
import './assets/Avenir Next LT Pro.ttf'
import './pages/career/CareerPage.css'
import EntryPage, {CareerPageLayout} from "./pages/career/EntryPage";
import {JobOverviewPage} from "./pages/career/JobPage";
import {JobBenefitsPage} from "./pages/career/BenefitPage";
import {ContactPage, ContactSuccessPage} from "./pages/career/ContactPage";
import {NotFoundPage} from "./pages/NotFound";
import {jobs} from "./constants/jobs";

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ff8040',
                    fontFamily: 'Avenir'
                },
                components: {
                    Button: {
                        colorPrimaryHover: '#666666'
                    },
                }
            }}
        >
            <BrowserRouter basename={'/'}>
                <Routes>
                    <Route element={<CareerPageLayout/>}>
                        <Route path={'/'} element={<EntryPage jobs={jobs}/>}/>
                        <Route path={'/bewerbung-erhalten'} element={<ContactSuccessPage/>}/>
                        {jobs.map((job) => {
                            const path = job.jobTitle.replace(' ', '-').toLowerCase();
                            return (
                                <React.Fragment key={path}>
                                    <Route path={`/${path}`} element={<JobOverviewPage job={job}/>}/>
                                    <Route path={`/${path}-2`} element={<JobBenefitsPage/>}/>
                                    <Route path={`/${path}-3`} element={<JobBenefitsPage/>}/>
                                    <Route path={`/${path}-4`} element={<ContactPage job={job}/>}/>
                                </React.Fragment>
                            );
                        })}
                        <Route path={'*'} element={<NotFoundPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;
