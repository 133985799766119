export type CareerJob = {
    jobTitle: string;
    workPlace: string;
    workTime: string;
    tasks: string[];
    niceToHaveSkills: string[];
}

export const jobs: CareerJob[] = [
    {
        jobTitle: 'Frontend Entwickler',
        workPlace: 'München & Remote',
        workTime: 'Vollzeit',
        tasks: [
            'Entwicklung und Verbesserung von leistungsfähigen Anwendungen auf Basis von Single Page Applications',
            'Agile Projektarbeit mit it werklerei KollegInnen und in Kundenteams von der Konzeption bis hin zur Umsetzung',
            'Beratung unserer Kunden hinsichtlich IT-Architektur, Frameworks sowie Projektmachbarkeit',
        ],
        niceToHaveSkills: [
            'Tiefgehende Erfahrungen mit modernen Standards und Frameworks, wie React, Vue.Js und Angular',
            'Sicherer Umgang mit Tools wie Less/Sass, Webpack, Node.js, Git, WebStorm, etc.',
            'Schnelle Auffassungsgabe und hohe Problemlösungskompetenz',
            'Wert auf hohe Code-Qualität',
        ],
    },
    {
        jobTitle: 'Java Entwickler',
        workPlace: 'München & Remote',
        workTime: 'Vollzeit',
        tasks: [
            'Entwicklung und Verbesserung von leistungsfähigen Anwendungen auf Basis von Single Page Applications',
            'Agile Projektarbeit mit it werklerei KollegInnen und in Kundenteams von der Konzeption bis hin zur Umsetzung',
            'Beratung unserer Kunden hinsichtlich IT-Architektur, Frameworks sowie Projektmachbarkeit'
        ],
        niceToHaveSkills: [
            'Tiefgehende Erfahrungen mit modernen Standards und Frameworks, wie Streams, Lombok und Microservices',
            'Sicherer Umgang mit Tools wie Gradle/Maven, Docker, Git, etc',
            'Schnelle Auffassungsgabe und hohe Problemlösungskompetenz',
            'Wert auf hohe Code-Qualität',
        ],
    },
    {
        jobTitle: 'DevOps Engineer',
        workPlace: 'München & Remote',
        workTime: 'Vollzeit',
        tasks: [
            'Entwicklung, Verbesserung und Neugestaltung von modernen, performanten und robusten Infrastruktur-Landschaften',
            'Agile Projektarbeit mit it werklerei KollegInnen und in Kundenteams von der Konzeption bis hin zur Umsetzung',
            'Beratung unserer Kunden hinsichtlich IT-Architektur, Frameworks sowie Projektmachbarkeit',
        ],
        niceToHaveSkills: [
            'Infrastructure as Code – Mentalität',
            'Einschlägige Erfahrungen mit Ci/Cd pipelines, public cloud environments, helm charts, etc',
            'Schnelle Auffassungsgabe und hohe Problemlösungskompetenz',
            'Wert auf hohe Code-Qualität',
        ],
    },
];